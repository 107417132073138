<template>
    <div class="wrap">
        <div class="img-wrap" @click="jumpToLink"><img :src="item.img " alt=""></div>
        <div class="desc" @click="jumpToLink">{{ item.introduce }}</div>
        <div class="time">活动时间：{{ item.time }}</div>
        <el-popover
            placement="top"
            width="400"
            trigger="click"
            :disabled="positionFlag"
            popper-class="pub-popover"
        >
            <select-position
                @hidePosition="hidePosition"
                v-if="singleposition"
                @confirmPosition="confirmPosition"
            />
            <div class="button-wrap" slot="reference" ref="button" @click="showPosition">
                立即推广
            </div>
        </el-popover>
    </div>
</template>

<script>
import Bus from '../../utils/bus.js';
import selectPosition from '../goodsPromotion/selectPromotionPosition.vue';
import thumbnail from '../../utils/thumbnail.js';

export default {
    name: 'activityModel',
    components: {
        selectPosition
    },
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {
            positionFlag: false,
            singleposition: false
        };
    },
    created() {
        this.item.img = thumbnail(this.item.img, {
            width: 630,
            height: 314
        });
    },
    methods: {
        showPosition() {
            this.singleposition = true;
        },
        hidePosition() {
            this.$refs.button.click();
        },
        async confirmPosition(e) {
            this.$refs.button.click();
            const data = await this.$request({
                url: '/api/activityPromotion/instantPromotionActivity',
                params: {
                    url: this.item.link,
                    positionId: e
                }
            });
            Bus.$emit('showActivityDialog', {
                data,
                introduce: this.item.backupShareTitle
            });
        },
        jumpToLink() {
            window.open(this.item.link);
        }
    }
};
</script>

<style lang="scss">
.pub-popover {
    height: 274px;
}
</style>
<style lang='scss' scoped>
.wrap {
    width: 317px;
    height: 279px;
    background: #FFFFFF;
   border: 1px solid #E6E6E6;
    border-radius: 5px;
    margin-right: 17px;
    margin-bottom: 16px;
    cursor: pointer;
    .img-wrap {
        width: 315px;
        height: 157px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 5px 5px 0 0;
        }
    }
    .desc {
        width:287px;
        margin: 15px auto;
        margin-bottom: 3px;
        color: #333;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .time{
        width:287px;
        margin: 0 auto;
        margin-bottom: 15px;
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .button-wrap {
        width: 287px;
        height: 32px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        color: #fff;
        background-image: linear-gradient(90deg, #FF1E32 0%, #FF3264 100%);
        border-radius: 16px;
        &:hover {
            background: #FF4F5D;
        }

    }
}
</style>

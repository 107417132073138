<template>
    <div class="wrap">
        <div class="top">
            <div class="image-wrap">
                <img src="https://kaola-haitao.oss.kaolacdn.com/42607e8c-4ec1-43a5-9d76-b733ec5ae93a_46_46.png" alt="">
            </div>
            <div class="title-wrap">
                <div class="main-title">主题推广</div>
                <div class="sub-title">为您精选活动主题，一键推广，活动页内任意商品成交后，您均可获得佣金</div>
            </div>
        </div>
        <channel @bizChange="bizChange($event)" :bizType="bizType"></channel>
        <div class="list-wrap" v-if="activityList && activityList.length>0">
            <activityModel v-for="(item,index) in activityList" :key="index" :item="item" />
            <div class="awrap" v-for="n in 20" :key="n" />
        </div>
        <div class="list-wrap loading"
             v-else-if="(!activityList || activityList.length===0) && loading"
             element-loading-text="拼命加载中"
             v-loading="loading"
        />
        <div class="list-wrap nodata" v-else-if="(!activityList || activityList.length===0) && !loading">
            <noData><p>暂无活动</p></noData>
        </div>
        <div class="list-wrap " v-else>
            <errorData>
                <div>
                    <p>数据加载失败</p>
                    <p @click="getActivityList" class="reload">重新加载</p>
                </div>
            </errorData>
        </div>
        <activityDialog />
    </div>
</template>

<script>
import activityModel from '../../components/activityPromotion/activityModel.vue';
import activityDialog from '../../components/activityPromotion/activityDialog.vue';
import noData from '../../components/common/noData.vue';
import errorData from '../../components/common/errorData.vue';
import channel from '../../components/common/channel.vue';
import { Message } from 'element-ui';

export default {
    name: 'activityPromotion',
    components: {
        activityModel,
        activityDialog,
        noData,
        errorData,
        channel
    },
    data() {
        return {
            activityList: [],
            loading: true,
            bizType: -1
        };
    },
    created() {
        this.getActivityList();
    },
    methods: {
        bizChange(bizType) {
            if(this.loading) {
                Message.error('数据加载中，请稍后')
                return;
            }

            this.loading = true;
            this.activityList = [];
            this.bizType = bizType
            this.getActivityList()
        },
        async getActivityList() {
            let params = {}
            if(this.bizType !== -1) {
                params.bizType = this.bizType
            }

            try {
                const data = await this.$request({
                    url: '/api/activityPromotion/getActivityList',
                    params,
                });
                this.activityList = data;
                this.$nextTick(() => {
                    this.loading =false;
                });
            } catch (error) {
                console.log('[request error]:', error)
            }


        }
    }
};
</script>

<style lang="scss" scoped>
    .wrap {
        .top {

            height: 80px;
            background: #FFFFFF;
            border: 1px solid #E3E3E3;
            border-radius: 4px;
            display: flex;
            align-items: center;
            padding-left: 13px;
            .image-wrap {
                width: 46px;
                height: 46px;
                img {
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                }
            }
            .title-wrap {
                margin-left: 10px;
                .main-title{
                    font-size: 16px;
                    font-weight: 700;
                    color: #333333;
                }
                .sub-title {
                    margin-top: 4px;
                    color: #333333;
                    font-size: 12px;
                }
            }
        }
        .list-wrap {
            margin: 16px -16px 16px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .reload {
                cursor: pointer;
                color: #ff1e32;
                text-decoration: underline;
            }
            .awrap {
                width: 317px;
                margin-right: 16px;
            }
        }
        .loading {
            margin-top: 150px;
            //background: #f5f5f5;
        }
        .nodata {
            position:relative;
            top: 120px;
        }
    }
</style>
